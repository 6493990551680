import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import AuthService from '../../services/auth.service'

import KICKappslogo1 from '../../imgs/poflo/logo200New.png'

import './nav.css'

export default function TopNav({ currentUser }) {
  useEffect(() => {
    window.scrollTo(0, 0)
    // console.log(currentUser)
    // console.log(document.cookie)

    const checkStatus = async () => {
      let user
      try {
        user = await AuthService.getCurrentUser()
      } catch (err) {
        // console.log(err)
        // currentUser.setCurrentUser(null)
        user = null
      }
      if (user) {
        currentUser.setCurrentUser(user.data)
        // console.log(user.data)
        // fetchData()
      }
    }
    checkStatus()
  }, [])

  const logOut = () => {
    document.cookie = 'loggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path/;'
    AuthService.logout()
  }

  return (
    <>
      <nav className="navbar navbar-expand navbar-dark topnav row">
        <div className="row">
          <div className="col-auto">
            <div className="navbar-brand">
              <img className="navlogo" src={KICKappslogo1} alt="POflow Main Menu" />
            </div>
          </div>
          {/* <Flapjack loggedIn={currentUser.currentUser ? true : false} /> */}
          <div className="col-auto">
            {currentUser.currentUser ? (
              <div className="row">
                <div className="col nav-item">
                  <Link to={'/installhelp'} className="navlink">
                    Install
                  </Link>
                </div>
                {/* <div className="col nav-item">
                  <Link to={'/profile'} className="navlink">
                    Profile
                  </Link>
                </div> */}
                <div className="col nav-item">
                  <a href="/login" className="navlink" onClick={logOut}>
                    Logout
                  </a>
                </div>
              </div>
            ) : (
              <div>
                {/* <div className="nav-item">
              <Link to={'/'} className="nav-link sizehide">
                Home
              </Link>
            </div> */}
                {/* <div className="nav-item">
              <Link to={'/login'} className="nav-link">
                Login
              </Link>
            </div> */}
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  )
}
