import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
// import { ButtonGroup, Form } from 'react-bootstrap'

import AuthService from '../../services/auth.service'
import API from '../../services/details.service'

import TopNav from './topnav'

// import Dcslogo1 from '../../imgs/dcslogo1.png'
import stock from '../../imgs/genericavatar.png'

import './nav.css'
// import { check } from 'prettier'

export default function Navbar({
  editMode,
  currentUser
  //  originalPath
}) {
  // const [currentUser, setCurrentUser] = useState(null)
  const [userAvatar, setUserAvatar] = useState(stock)
  // const [edit, setEdit] = useState(false)
  // console.log(originalPath)
  // const editMode = (e) => {
  //   setEdit(!edit)
  //   console.log(edit)
  // }
  useEffect(() => {
    window.scrollTo(0, 0)
    // console.log(currentUser)
    const fetchData = async () => {
      const response = await API.get()
      if (response.data.avatarURL) {
        setUserAvatar(response.data.avatarURL)
      }
    }
    const checkStatus = async () => {
      let user
      try {
        user = await AuthService.getCurrentUser()
      } catch (err) {
        // console.log(err)
        // currentUser.setCurrentUser(null)
        user = null
      }
      if (user) {
        // console.log(user.data)
        currentUser.setCurrentUser(user.data.loggedIn === true ? user.data : null)
        // history.push(originalPath)

        // console.log(user.data)
        fetchData()
      }
    }
    checkStatus()
  }, [])

  // const logOut = () => {
  //   AuthService.logout()
  // }

  return (
    <div className="allnav">
      {/* Topnav section */}
      <TopNav currentUser={currentUser} />

      {/* lowernav section */}

      {currentUser.currentUser && (
        <div id="all-btns" className="lowernav pb-1">
          <div className=" navbuttons">
            <div className="ml-2 row">
              {currentUser.currentUser.role === 'staff' && (
                <div className="staffnav">
                  <div className="col userspace">
                    <Link to={'/profile'} className="nav-link2">
                      <div
                        className="avatar puff"
                        title="My Profile"
                        style={{
                          backgroundImage: `url(${userAvatar ?? stock})`
                        }}
                      >
                        <small className="username card-title small fw-bold ml-5">
                          {currentUser.currentUser.username}
                        </small>
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {currentUser.currentUser.role === 'director' && (
                // {(currentUser.currentUser.role === 'director' ||
                //   currentUser.currentUser.role === 'admin') && (
                <div className="dirnav">
                  <div className="col">
                    <Link to={'/profile'} className="nav-link2">
                      <div
                        className="avatar puff"
                        title="My Profile"
                        style={{
                          backgroundImage: `url(${userAvatar ?? stock})`
                        }}
                      >
                        <small className="username card-title small fw-bold">
                          {currentUser.currentUser.username}
                        </small>
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {currentUser.currentUser.role === 'admin' && (
                <div className="row">
                  <Link
                    to="/dashboard"
                    className="col btn btn2 nav-link2 fw-bold btn-blue ml-2"
                    name="dashbtns"
                    title="DASHBOARD"
                  >
                    {/* <b className="text-light upper fw-bold btn-text">Dashboard</b> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="white"
                      class="bi bi-speedometer mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                      <path
                        fill-rule="evenodd"
                        d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z"
                      />
                    </svg>
                  </Link>
                  <Link
                    to="/admin"
                    className="col btn btn2 nav-link2 fw-bold bg-light ml-2"
                    name="dashbtns"
                    title="SETTINGS"
                  >
                    {/* <b className="text-primary upper fw-bold btn-text">Settings</b> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#23639b"
                      class="bi bi-gear-wide mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z" />
                    </svg>
                  </Link>
                  <div className="col">
                    <Link to={'/profile'} className="nav-link2">
                      <div
                        className="avatar puff"
                        title="My Profile"
                        style={{
                          backgroundImage: `url(${userAvatar ?? stock})`
                        }}
                      >
                        <small className="username card-title small fw-bold ml-5">
                          {currentUser.currentUser.username}
                        </small>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>

            {/* {currentUser.currentUser.role === 'admin' && (
              <div className="custom-switch1 btn-sm">
                <div>
                  EDIT
                  <Form.Switch type="switch" id="edit-page" onChange={editMode} />
                </div>
              </div>
            )} */}
          </div>
        </div>
      )}
    </div>
  )
}
