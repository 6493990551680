import React from 'react'
import { Switch, Route } from 'react-router-dom'
import AdminBoard from './Boards/Admin'

import POflowDashboard from '../views/POflow'
import PDFoutput from '../views/POflow/PDFoutput'

import Install from './InstallPWA'
import Login from './Login'
import Register from './Register'
import Profile from './Profile'

import '../App.css'

export default function Main({ updateMascot, edit }) {
  return (
    <div id="maincontent" className="bkg">
      <Switch>
        <Route
          exact
          path={'/dashboard'}
          // component={POflowDashboard}
          component={() => <POflowDashboard />}
        />

        <Route
          exact
          path={'/output'}
          // component={POflowDashboard}
          component={() => <PDFoutput />}
        />

        {/* <Route exact path={'/installPWA'} component={() => <Install />} /> */}

        {/* APP ROUTES */}
        <Route exact path="/admin" component={AdminBoard} />
        <Route exact path="/profile" component={Profile} />
        {/* APP ROUTES */}

        <Route exact path={['/', '/login']} component={Login} />
        <Route exact path="/register" component={Register} />
      </Switch>
    </div>
  )
}
