import React from 'react'
import { Tr, Td } from 'react-super-responsive-table'
// import trashIcon from 'bootstrap-icons/icons/trash-fill.svg'
// import permissionIcon from 'bootstrap-icons/icons/person-plus.svg'

// import ModalButton from '../../Modal'
// import DeleteUserForm from '../../Forms/Delete/User.delete'
// import AddUserPermissionsForm from '../../Forms/Update/AddPermissions.update'

export default function MyRow(props) {
  // console.log(props)
  return (
    <Tr key={props.key}>
      {Object.values(props.data).map((x, i) => (
        <Td key={i}>{x}</Td>
      ))}
    </Tr>
  )
}
