import React, { useState, useEffect } from 'react'

import { Button, Form, Alert } from 'react-bootstrap'
import Select from 'react-select'

import MyTable from '../../Table'
import MyRow from '../../Table/TRows/PurchaseRow'
import MyHeader from '../../Table/THeaders/PurchaseForm.header'
import API from '../../../services/poflow.service'
import Loading from '../../Loading'
// import { propTypes } from 'react-bootstrap/esm/Image'

export default function RejectRequestForm(props) {
  console.log(props)
  const [validated, setValidated] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [vendors, setVendors] = useState([])
  const [accounts, setAccounts] = useState([])
  const [shippingHandling, setShippingHandling] = useState(props.post.shippingHandling)
  // const [allData, setData] = useState([
  //   {
  //     item_name: props.item_name,
  //     description: props.description,
  //     unit_price: props.unit_price,
  //     quantity: props.quantity,
  //     total_price: props.total_price
  //   }
  // ])
  const [allData, setData] = useState(props ? [...props.post.data] : [])
  const reducer = (partialSum, a) => {
    return partialSum + a.total_price
  }
  const [grandTotal, setGrandTotal] = useState(
    props ? props.post.data.reduce(reducer, 0) : 0
  )
  const [location, setLocation] = useState({
    reqId: props.post.poData.requisitionId,
    loc: props.post.poData.locationCode
  })
  const [flows, setFlows] = useState([])

  const resetState = () => {
    // setVendors([])
    // setAccounts([])
    setShippingHandling(props.post.shippingHandling)
    setData(props ? [...props.post.data] : [])
    setGrandTotal(props ? props.post.data.reduce(reducer, 0) : 0)
    setLocation({
      reqId: props.post.poData.requisitionId,
      loc: props.post.poData.locationCode
    })
    setValidated(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.requestFormData()
        // console.log(vendorResponse.data)
        // console.log(accountsResponse.data)
        setLoading(false)
        setVendors(response.data.vendorList)
        setAccounts(response.data.accountCodes)
        setFlows(response.data.formFlows)
        // console.log(props)
      } catch (err) {
        console.log(err)
        // props.history.push('/login')
        // window.location.reload()
      }
    }
    if (isLoading) {
      fetchData()
    }
  }, [isLoading])

  const updateFields = (i, k, v) => {
    const currentData = [...allData]
    // console.log(typeof v)
    if (typeof v === 'string') {
      currentData[i][k] = v || currentData[i][k]
    } else {
      currentData[i][k] = v.label || currentData[i][k]
      currentData[i]['vendorId'] = v.value || currentData[i][k]
    }
    if (currentData[i][k] !== allData[i][k]) {
      setData(currentData)
    }
    // console.log(currentData)
  }

  function round(num) {
    const m = Number((Math.abs(num) * 100).toPrecision(15))
    return (Math.round(m) / 100) * Math.sign(num)
  }

  const updateTotal = (i, k, v) => {
    // console.log(v)
    const currentData = [...allData]
    const k2 = k === 'quantity' ? 'unit_price' : 'quantity'
    // console.log(`i: ${i}, k: ${k}, k2: ${k2}, v: ${v}`)
    currentData[i][k] = parseFloat(v || currentData[i][k])
    currentData[i].total_price = round(currentData[i][k] * currentData[i][k2])
    setData(currentData)
    updateGrandTotal(currentData)
  }
  const updateGrandTotal = (data) => {
    let total = 0
    data.forEach((obj) => {
      if (typeof obj.total_price == 'number') {
        // console.log(obj)
        total += obj.total_price
      }
    })
    setGrandTotal(total)
  }

  const addRow = () => {
    const currentData = [...allData]
    currentData.push({
      item_name: '',
      description: '',
      unit_price: 0.0,
      quantity: 0.0,
      total_price: 0.0
    })
    setData(currentData)
  }

  const deleteRow = (i) => {
    const currentData = [...allData]
    if (currentData.length > 1) {
      currentData.splice(i, 1)
      // console.log(currentData)
      setData(currentData)
      updateGrandTotal(currentData)
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setValidated(false)

    const formData = new FormData(e.target)
    const formDataObj = Object.fromEntries(formData.entries())
    const ids = formDataObj.requisitionId.split('|')
    let data = {
      // id: {
      //   requisitionId: ids[0],
      //   locationCode: ids[1]
      // },

      formId: props.post.id,
      items: allData,
      requestFormData: {
        requisitionId: ids[0],
        vendorId: formDataObj.vendorId,
        accountId: formDataObj.accountId,
        shippingHandling: formDataObj.shippingHandling,
        rejected: false,
        reason: null
      }
    }

    const sendData = async (data) => {
      props.onHide()
      // console.log(data)
      await API.updateForm(data)
      props.post.setLoading(true)
    }
    const form = e.target
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
      setValidated(true)
    } else {
      resetState()
      sendData(data)
    }
  }

  const closeModal = () => {
    props.onHide()
    resetState()
  }

  const locationUpdate = (e) => {
    const val = e.target.value.split('|')

    setLocation({
      reqId: val[0],
      loc: val[1]
    })
  }

  return (
    <>
      {isLoading && <Loading />}
      <Form noValidate validated={validated} onSubmit={onFormSubmit}>
        <h3>{`Reject Reason: ${props.reason}`}</h3>
        <div className="row">
          <Form.Group className="col-6">
            <Form.Label>
              Department Selection: <span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Form.Control
              required
              name="requisitionId"
              as="select"
              onChange={locationUpdate}
              defaultValue={`${location.reqId}|${location.loc}`}
            >
              {flows.map((obj, i) => (
                <option key={i} value={`${obj.requisitionId}|${obj.locationCode}`}>
                  {obj.requisitionId}
                </option>
              ))}
              {/* <option value="">Select the department...</option>
              <option value="CTE|dc501">CTE</option>
              <option value="Food|dc">Food Service</option>
              <option value="Maint|dc015">Maintenance</option>
              <option value="TPR|dc014">Transportation</option>
              <option value="DCHS|dc501">High School</option>
              <option value="DCMS|dc301">Middle School</option>
              <option value="DCES|dc211">Elementary School</option>
              <option value="DCPK|dc212">Preschool</option>
              <option value="SPED|dc001">Special Education</option>
              <option value="TEST|dc001">FEATURE TESTING ONLY</option> */}
            </Form.Control>
          </Form.Group>

          {location && (
            <Form.Group className="col-6">
              <Form.Label>
                Account Selection: <span style={{ color: 'red' }}>*</span>
              </Form.Label>
              <Select
                name="accountId"
                options={accounts.filter((obj) => obj.locationCode === location.loc)}
                defaultValue={
                  accounts.filter((obj) => obj.value === props.post.poData.accountId)[0]
                }
              />
            </Form.Group>
          )}

          <Form.Group className="col-12">
            <Form.Label>
              Vendor Selection: <span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Select
              name="vendorId"
              className="override-input"
              options={vendors}
              defaultValue={
                vendors.filter((obj) => obj.value === props.post.poData.vendorId)[0]
              }
            />
          </Form.Group>

          <Form.Group className="col-12">
            <MyTable
              tData={allData}
              sample={{ ...allData[0] }}
              header={MyHeader}
              row={MyRow}
              purchaseFuncs={{
                updateTotal,
                updateFields,
                deleteRow
              }}
            />
          </Form.Group>
          <Form.Group className="col-2">
            <button type="button" className="btn btn-secondary " onClick={addRow}>
              Add Item
            </button>
          </Form.Group>
        </div>
        <div className="row">
          <Form.Group className="col-6">
            <Form.Label>
              Shipping and Handling Cost: <span style={{ color: 'red' }}>*</span>
            </Form.Label>
            <Form.Control
              required
              type="number"
              step={0.01}
              onChange={(e) => setShippingHandling(parseFloat(e.target.value))}
              name="shippingHandling"
              placeholder="00.00"
              defaultValue={shippingHandling}
            />
          </Form.Group>

          <div className="col-10">
            <h5>
              Grand Total:{' '}
              <span>
                ${(parseFloat(grandTotal) + parseFloat(shippingHandling)).toFixed(2)}
              </span>
            </h5>
          </div>
        </div>
        {validated && (
          <Alert variant="danger">
            Missing required fields or nothing has been entered.
          </Alert>
        )}
        <Button variant="success m-2" type="submit">
          Submit
        </Button>
        <Button variant="warning m-2" onClick={closeModal} type="button">
          Exit
        </Button>
      </Form>
    </>
  )
}
