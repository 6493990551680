import React from 'react'
import { Link } from 'react-router-dom'
import './footer.css'

import kickicon from '../../imgs/kickicon.png'

export default function Footer() {
  return (
    <footer className="container-fluid">
      <div className="foot-grid">
        <div className="foot1 center">
          {/* <p className="text-light center">
            268 Bulldog Dr
            <br />
            West Union, WV
            <br />
            26456
          </p> */}
        </div>
        <div className="foot2 center">
          {/* <p className="text-light center">
            Phone: 304-873-2300
            <br />
            Fax: 304-873-2210
            <br />
            <Link to={'/privacypolicy'} className="text-light">
              privacy policy
            </Link>
          </p> */}
        </div>
        <div className="foot3 center">
          {/* <h2 className="text-light">“NOW IS THE TIME: UNITED TO MAKE A DIFFERENCE.”</h2> */}
        </div>
        <div className="foot4">
          {/* <span className="text-muted center fcredits">
            <b className="copyright">
              Copyright &copy; {new Date().getFullYear()} KICKapps llc
            </b>
          </span> */}
          <span className="text-muted">
            <a
              className="row logo-group"
              href="https://kickapps.io"
              target="_blank"
              // rel="noreferrer"
              rel="noopener noreferrer"
            >
              {' '}
              <h5 className="col-auto text-dark fcredits1">
                KICK<span className="blue">apps </span>
                <img src={kickicon} className="col-auto iconic" alt="KICKapps logo" />
                <span className="text-secondary">
                  Copyright {new Date().getFullYear()}
                </span>
              </h5>
            </a>
          </span>
        </div>
        {/* <div className="row">
          <div class="col-12 theme-switch-wrapper center">
            <label class="theme-switch" for="checkbox">
              <input type="checkbox" id="checkbox" />
              <div class="slider round"></div>
            </label>
            <em> Dark Mode</em>
          </div>
        </div> */}
      </div>
    </footer>
  )
}

// <div className="footerfix footer mt-1">
//         <div className="row">
//           <div className="col">
//             <div className="row mt-2 pt-5">
//               <div className="col-6 col-md-6 col-sm-6 col-xs-6 order-1">
//                 <p className="text-light">
//                   268 Bulldog Dr
//                   <br />
//                   West Union, WV 26456
//                 </p>
//               </div>
//               <div className="col-12 col-md-12 col-sm-12 col-xs-12 order-3 pt-3">
//                 <h2 className="text-light">
//                   “NOW IS THE TIME: UNITED TO MAKE A DIFFERENCE.”
//                 </h2>
//               </div>
//               <div className="col-6 col-md-6 col-sm-6 col-xs-6 order-2">
//                 <p className="text-light">
//                   Phone: 304-873-2300
//                   <br />
//                   Fax: 304-873-2210
//                 </p>
//               </div>
//             </div>
//             <span className="text-muted col align-self-center ">
//               <b className="copyright">
//                 Copyright © 2021 | KICKapps POflow |{' '}
//                 <span className="fcredits">
//                   <a
//                     href="https://kickapps.org"
//                     target="_blank"
//                     rel="noreferrer"
//                     rel="noopener noreferrer"
//                   >
//                     {' '}
//                     KICKapps
//                     <img src={kickicon} className="iconic" alt="KICKapps logo" />

//                   </a>
//                 </span>
//               </b>
//             </span>
//           </div>
//         </div>
//       </div>
