import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import API from '../../services/details.service'

import Loading from '../Loading'
import ModalButton from '../Modal'
import UpdateProfile from '../Forms/Update/UserDetails.update'
import SupportForm from '../Forms/Update/Support.send'
import ResetPasswordForm from '../Forms/Update/Password.update'

import Header from '../../components/Header'

import './profile.css'

import ProfBkg from '../../imgs/prof-bkg.jpg'
import stock from '../../imgs/genericavatar.png'
import poFlo from '../../imgs/poflo/logo200New.png'

export default function Profile(props) {
  const [isLoading, setLoading] = useState(true)
  const [userDetails, setUserDetails] = useState({})
  const [currentUser, setCurrentUser] = useState(null)
  const [bannerType, setBannerType] = useState(['all'])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get()
        setLoading(false)
        setUserDetails(response.data)
      } catch (err) {
        props.history.push('/login')
        window.location.reload()
      }
    }
    if (isLoading) {
      window.scrollTo(0, 0)
      fetchData()
    }
  }, [isLoading, props])

  const showContent = () => {
    return (
      <div className="container">
        <Header bannerType={bannerType} currentUser={{ currentUser, setCurrentUser }} />
        <div className="card-clear mb-2 round2 shadow-sm row">
          <div className="row col-12">
            <div className="col-12 center">
              <h1 className="card-title center mt-3">My Profile</h1>
            </div>
          </div>
          <div
            className="bpic shadow-b"
            alt="Your Profile image"
            style={{
              backgroundImage: `url(${userDetails.avatarURL || stock})`
            }}
          ></div>
          <div
            className="profile-bkg border1 card-img-top"
            alt="Your Background image"
            style={{
              backgroundImage: `url(${userDetails.backgroundURL || ProfBkg})`
            }}
          ></div>

          <div id="profile-data" className="container mt-2">
            <div className="row personal-info mb-2 pb-2 round1 ml-auto mr-auto pt-4">
              <div className="col-auto center">
                <div className="row">
                  <div className="col-4">
                    <div className="col">
                      <strong>Name:&nbsp;</strong>
                    </div>
                    <div className="col">
                      <strong>Email:&nbsp;</strong>
                    </div>
                    <div className="col">
                      <strong>Facility:&nbsp;</strong>
                    </div>
                    <div className="col">
                      <strong>Title:&nbsp;</strong>
                    </div>
                    <div className="col">
                      <strong>Position:&nbsp;</strong>
                    </div>
                    <div className="col">
                      <strong>Phone:&nbsp;</strong>
                    </div>
                  </div>
                  <div className="col-7 offset-1 left">
                    <div className="col">
                      <b>
                        {userDetails.prefix}
                        &nbsp;
                        {userDetails.firstName}
                        &nbsp;
                        {userDetails.lastName}
                      </b>
                    </div>
                    <div className="col">
                      <b>{userDetails.email}</b>
                    </div>
                    <div className="col">
                      <b>{userDetails.facility}</b>
                    </div>
                    <div className="col">
                      <b>{userDetails.title}</b>
                    </div>
                    <div className="col">
                      <b>{userDetails.subject_grade}</b>
                    </div>
                    <div className="col">
                      <b>
                        {userDetails.phone} ext: {userDetails.ext}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              {/* <div className="bio-header center col-sm-auto col-lg-auto">
                <strong>Professional Details</strong>
              </div> */}
              <div
                alt="details"
                className="col-10 center border1 round1 ml-auto mr-auto mb-2 round1"
              >
                <div className="card-body center">
                  <b>
                    {userDetails.courses_role &&
                      (userDetails.courses_role.includes('\r\n') ? (
                        <ul>
                          {userDetails.courses_role.split('\r\n').map((line, i) => (
                            <li key={i}>{line}</li>
                          ))}
                        </ul>
                      ) : (
                        userDetails.courses_role
                      ))}
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div id="prof-buttons" className="container pb-3 pt-2">
            <div className="row mb-5">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-10 center border3 round2">
                <h4 className="bg-light border-btm">Available Apps</h4>

                <div className="col-6 center mt-5 mb-5">
                  <Link to={'/dashboard'}>
                    <div className="btn round2 border1 shadow-sm p-3">
                      <div className="center">
                        <img src={poFlo} alt="PO Flo" className="img-200b" />
                      </div>
                    </div>
                  </Link>
                  <br />
                  <small>Paperless Purchase Order System</small>{' '}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row center border1 round1">
                <ModalButton
                  form={SupportForm}
                  modalTitle="What issue are you having?"
                  button={true}
                  btnClasses="col-auto btn-left btn-lg btn-blue text-white fw-bold"
                  btnText="App Support"
                  updateDeleteInfo={{ setLoading }}
                />

                <ModalButton
                  form={ResetPasswordForm}
                  modalTitle="Reset Password."
                  button={true}
                  btnClasses="col-auto btn-middle btn-lg btn-warning fw-bold"
                  btnText="Reset Password"
                />

                <ModalButton
                  form={UpdateProfile}
                  modalTitle="Edit Your Profile."
                  button={true}
                  btnClasses="col-auto btn-right btn-lg btn-light fw-bold"
                  btnText="Edit Profile"
                  updateDeleteInfo={{ ...userDetails, setLoading }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="profile">
      {isLoading && <Loading />}
      <div className="pt-5 mt-5 bottom">{showContent()}</div>
    </div>
  )
}
