import React, { useState } from 'react'

import { Button, Form, Alert } from 'react-bootstrap'
import MyTable from '../../Table'
import MyHeader from '../../Table/THeaders'
import MyRow from '../../Table/TRows/PODisplay.row'

import API from '../../../services/poflow.service'

export default function POApproveForm(props) {
  const [validated, setValidated] = useState(false)
  const [status, setStatus] = useState(false)
  const [overAllstatus, setoverAllstatus] = useState(props.status)
  const [final, setFinal] = useState('')
  const [rejectMsg, setMsg] = useState('')
  // console.log(props)

  const resetState = () => {
    setValidated(false)
    setStatus(false)
    setoverAllstatus(props.status)
    setMsg('')
    setFinal('')
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formDataObj = Object.fromEntries(formData.entries())
    // console.log(formDataObj)

    let data = {
      id: props.post.id,
      approval: formDataObj.finalApproval === 'approve' ? true : false,
      approval: status,
      stage: props.status,
      switch: overAllstatus.switchName
        ? formDataObj[overAllstatus.switchName] === 'true'
          ? true
          : false
        : null,
      reason: rejectMsg || null
    }

    const sendData = async (data) => {
      props.onHide()
      // console.log(data)
      await API.status(data)
      props.post.setLoading(true)
    }
    const form = e.currentTarget
    if (
      form.checkValidity() === false ||
      !status ||
      (final !== 'approve' && !rejectMsg)
      //|| (props.status === 'approval2' && !formDataObj.local)
    ) {
      e.preventDefault()
      e.stopPropagation()
      setValidated(true)
    } else {
      resetState()
      sendData(data)
    }
  }

  const closeModal = () => {
    props.onHide()
    resetState()
  }

  const calculateTotal = (arr) => {
    const reducer = (previousValue, currentValue) => previousValue + currentValue
    const amounts = arr.map((obj) => obj.total_price)
    return amounts.reduce(reducer)
  }

  const approve_reject = (e) => {
    // console.log(e.target.id)
    setStatus(e.target.id)
    setValidated(false)
  }

  const finalCheck = (e) => {
    // console.log(e.target.value)
    setFinal(e.target.value)
  }

  const createMsg = (e) => {
    // console.log(e.target.value)
    setMsg(e.target.value)
  }

  return (
    <>
      {/* <h2>Are you sure you want to Approve this purchase order?</h2> */}
      <MyTable
        tData={props.post.data}
        sample={{ ...props.post.data[0] }}
        header={MyHeader}
        row={MyRow}
      />
      <h4> Shipping and Handling: ${props.post.shippingHandling}</h4>
      <h4>
        Grand total: $
        {(calculateTotal(props.post.data) + props.post.shippingHandling).toFixed(2)}
      </h4>
      {props.post.quote && (
        <h5>
          A quote has been included. To view, click{' '}
          <a href={props.post.quote} target="_blank">
            here
          </a>
          .
        </h5>
      )}
      <Form noValidate validated={validated} onSubmit={onFormSubmit}>
        {overAllstatus.switch == null && overAllstatus.switchName && (
          <fieldset>
            <Form.Group className="mb-3">
              <Form.Label as="legend" column sm={2}>
                Will this purchase order be for {overAllstatus.switchName} or county?
                <span style={{ color: 'red' }}>*</span>
              </Form.Label>

              <Form.Check
                type="radio"
                label={<span style={{ color: 'black' }}>{overAllstatus.switchName}</span>}
                name={overAllstatus.switchName}
                value={true}
                id={overAllstatus.switchName}
              />
              <Form.Check
                type="radio"
                label={<span style={{ color: 'black' }}>County</span>}
                name={overAllstatus.switchName}
                value={false}
                id="county"
              />
            </Form.Group>
          </fieldset>
        )}
        <fieldset>
          <Form.Group className="mb-3" onChange={approve_reject}>
            <Form.Label as="legend" column sm={2}>
              <strong style={{ color: 'black' }}>
                Approve or Reject: <span style={{ color: 'red' }}>*</span>
              </strong>
            </Form.Label>

            <Form.Check
              type="radio"
              label={<span style={{ color: 'black' }}>Approve</span>}
              name="approveReject"
              id="approve"
            />
            <Form.Check
              type="radio"
              label={<span style={{ color: 'black' }}>Reject</span>}
              name="approveReject"
              id="reject"
            />
          </Form.Group>
        </fieldset>

        {status === 'approve' && (
          <>
            <Form.Group className="col-4">
              <Form.Label>
                <strong style={{ color: 'black' }}>
                  Type the word "approve" to continue.
                  <span style={{ color: 'red' }}>*</span>
                </strong>
              </Form.Label>
              <Form.Control
                onChange={finalCheck}
                name="finalApproval"
                as="input"
                style={{ borderColor: 'rgba(0,0,0,0.2)', background: 'white' }} //TURNS OFF FORM VALIDATION
                maxLength={7}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="col-4">
              <Form.Label>
                <strong style={{ color: 'black' }}>
                  Would you like to add a note about this purchase order?
                </strong>
              </Form.Label>
              <Form.Control
                onChange={createMsg}
                style={{ borderColor: 'rgba(0,0,0,0.2)', background: 'white' }} //TURNS OFF FORM VALIDATION
                name="rejectMsg"
                as="textarea"
                rows={4}
                cols={50}
                maxLength={500}
              />
              <p>{rejectMsg.split('').length}/500</p>
            </Form.Group>
          </>
        )}

        {status === 'reject' && (
          <Form.Group className="col-4">
            <Form.Label>
              <strong style={{ color: 'black' }}>
                Give a reason for rejection to continue.
                <span style={{ color: 'red' }}>*</span>
              </strong>
            </Form.Label>
            <Form.Control
              onChange={createMsg}
              style={{ borderColor: 'rgba(0,0,0,0.2)', background: 'white' }} //TURNS OFF FORM VALIDATION
              name="rejectMsg"
              as="textarea"
              rows={4}
              cols={50}
              maxLength={500}
            />
            <p>{rejectMsg.split('').length}/500</p>
          </Form.Group>
        )}

        {validated && (
          <Alert variant="danger">
            Missing required fields or nothing has been entered.
          </Alert>
        )}

        <Button variant="success m-2" type="submit">
          Submit
        </Button>
        <Button variant="warning m-2" onClick={closeModal} type="button">
          Cancel
        </Button>
      </Form>
    </>
  )
}
