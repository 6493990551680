import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

// import Header from './components/Header'

import Main from './components/Main'
import Footer from './components/Footer'

import HttpsRedirect from 'react-https-redirect'
import { BrowserRouter as Router } from 'react-router-dom'

export default function App() {
  const [bannerType, setBannerType] = useState(['all'])
  const [edit, setEdit] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)

  const editMode = (e) => {
    setEdit(!edit)
  }

  // const updateMascot = (newMascot, newType = false) => {
  //   if (newMascot !== mascot) {
  //     setMascot(newMascot)
  //     if (newType) {
  //       setBannerType(['all', newType])
  //     } else {
  //       setBannerType(['all'])
  //     }
  //   }
  // }

  return (
    <div className="App grid-container">
      <HttpsRedirect>
        <Router basename="/">
          <div className="griditem1">
            {/* <Header
              editMode={editMode}
              mascot={mascot}
              bannerType={bannerType}
              currentUser={{ currentUser, setCurrentUser }}
            /> */}
          </div>
          <div className="griditem2">
            <Main />
          </div>
          <div className="griditem3">
            <Footer />
          </div>
        </Router>
      </HttpsRedirect>
    </div>
  )
}
