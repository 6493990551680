import React, { useEffect, useState } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
// import { table, thead, tr, td, th } from 'react-super-responsive-table'
// import 'react-super-responsive-table/dist/SuperResponsivetableStyle.css'

import plusIcon from 'bootstrap-icons/icons/plus.svg'

import Header from '../../components/Header'

import ModalButton from '../../components/Modal'
import PurchaseRequestForm from '../../components/Forms/Create/PurchaseRequest.create'
import API from '../../services/poflow.service'
import Loading from '../../components/Loading'
import POApproveForm from '../../components/Forms/Update/PO.approve'
import POEditForm from '../../components/Forms/Update/PO.edit'
import RejectRequestForm from '../../components/Forms/Update/PO.reject'

import PrintIt from './PDFoutput'
// import Mytable from '../../components/table'
// import MyHeader from '../../components/table/THeaders'
// import MyRow from '../../components/table/TRows/PO.formView.row'

import poFlo from '../../imgs/poflo/logo200New.png'
// import plus from '../../imgs/plus50.png'

export default function POflowDashboard(props) {
  const [content, setContent] = useState([])
  const [history, setHistory] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [reject, setRejected] = useState([])
  const [currentUser, setCurrentUser] = useState(null)
  const [bannerType, setBannerType] = useState(['all'])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.getAll()
        const historyResponse = await API.getHistory()
        // console.log(historyResponse.data)
        setLoading(false)
        setContent(response.data)
        setHistory(historyResponse.data)
      } catch (err) {
        props.history.push('/login')
        window.location.reload()
      }
    }
    if (isLoading) {
      window.scrollTo(0, 0)
      // props.updateMascot(dawg)
      fetchData()
    }
  }, [isLoading, props])

  useEffect(() => {
    const getReject = async () => {
      try {
        const response = await API.getRejected()
        // console.log(response.data)
        setRejected(response.data)
      } catch (err) {
        console.log(err)
      }
    }
    if (isLoading) {
      getReject()
    }
  }, [isLoading])

  // console.log(content)

  const formFormatter = (c, hist = false) => {
    const currentData = [...c]

    const jsxFormatting = (currentData, form1, form2) => {
      return (
        <>
          <table key={currentData.length} className="table table-hover overflow-no">
            <thead>
              <tr className="row">
                <th className="col">Department</th>
                <th className="col">Email</th>
                <th className="col">Total</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((row, i) => (
                <div key={i}>
                  <ModalButton
                    button={true}
                    status={row.header.stage}
                    btnClasses="shadow-no fw-bold col-12"
                    btnText={
                      <tr className="row">
                        <td>
                          <input className="hiddenbox" type="checkbox" />
                        </td>
                        <td className="col">{row.header.requisitionId}</td>
                        <td className="col">{row.header.email}</td>
                        <td className="col">${row.header.total}</td>
                      </tr>
                    }
                    form={row.poData ? form1 : form2}
                    modalTitle="Review purchase order."
                    updateDeleteInfo={{
                      id: row.header.formId,
                      shippingHandling: row.header.shippingHandling,
                      quote: row.header.quote,
                      reason: row.header.reason,
                      // shippingHandling: row.header.
                      setLoading,
                      data: row.data,
                      poData: row.poData
                    }}
                  />
                </div>
              ))}
            </tbody>
          </table>
        </>
      )
    }

    if (hist) {
      return jsxFormatting(currentData, PrintIt, PrintIt)
    }
    return jsxFormatting(currentData, POEditForm, POApproveForm)
    // return jsxFormatting(currentData)
  }

  return (
    <div className="appcontent mt-4 pt-5">
      <Header bannerType={bannerType} currentUser={{ currentUser, setCurrentUser }} />
      {isLoading && <Loading />}

      <Container fluid>
        <div className="mt-4 pt-4 round2">
          <Row className="m-1">
            <div className="ml-4" id="newpo" title="New PO">
              <ModalButton
                classes="plusbutton"
                title="New PO"
                icon={plusIcon}
                alt="Make a purchase request."
                width={80}
                height={80}
                form={PurchaseRequestForm}
                modalTitle="Make a purchase request."
                button={true}
                // btnText={' + '}
                btnClasses="plusicon center middle btn-lg btn-blue border1 round1 text-white"
                updateDeleteInfo={{ setLoading }}
              />
            </div>
            <div className="col-12 center">
              <h3>DASHBOARD</h3>
            </div>
          </Row>
          <Row className="m-1">
            <Col
              lg={8}
              md={8}
              sm={10}
              className="border1 bg-white round2 center pt-3 mt-3"
            >
              {/* <div className="row">
                <div className="col-auto row pl-4">
                  <p className="col-auto text-info puff2 pointer">Sort ↕</p>
                  <p className="col-auto text-info puff2 pointer">Show</p>
                </div>
               
                <div className="col right">
                  <input
                    className="col-6-lg col-6-sm bg-light border1 round1"
                    placeholder="Search Pending"
                  />
                </div>
              </div> */}

              <div>
                <div className="border1 round1 mb-3 m-1 mt-3 flo-box">
                  <h5 className="center upper history-header">History:</h5>

                  {history.length > 0 && formFormatter(history, true)}
                </div>
              </div>
            </Col>

            <Col
              lg={8}
              md={8}
              sm={10}
              className="border1 bg-white round2 center pt-3 mt-3 pb-3"
            >
              <div className="border1 round1 m-1 mt-3 flo-box">
                <h5 className="center upper pending-header">Pending:</h5>

                {content.length > 0 && (
                  <div className="container-100">
                    {formFormatter(content)}
                    {/* TABLE WILL GO HERE USING THE TABLE COMPONENT */}
                  </div>
                )}
                {/* <i>Pending PO go here.</i> */}
              </div>
            </Col>
          </Row>
          <Row className="pb-5 m-1">
            <Col
              lg={8}
              md={8}
              sm={10}
              className="border1 bg-white round2 center pt-3 mt-3 mb-4"
            >
              {/* <div className="row">
                <div className="col-auto">
                  <p className="btn-md text-info ml-2 pointer puff2">Sort ↕</p>
                </div>
                <div className="col right">
                  <input
                    className="col-6-lg col-6-sm bg-light border1 round1"
                    placeholder="Search Rejected"
                  />
                </div>
              </div> */}

              <div className="border1 round1 m-1 mt-1 mb-3 flo-box">
                <h5 className="rejected-header upper">Rejected</h5>
                <table
                  key={reject.length}
                  className="table table-hover table-zoom overflow-no"
                >
                  <thead>
                    <tr className="row">
                      <th className="col">Department</th>
                      <th className="col">Email</th>
                      <th className="col">Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reject.length > 0 &&
                      reject.map((item, i) => (
                        <div key={i}>
                          <ModalButton
                            button={true}
                            btnClasses="shadow-no fw-bold col-12 gapfix"
                            btnText={
                              <tr className="row">
                                <td className="col">{item.header.requisitionId}</td>
                                <td className="col">{item.header.email}</td>
                                <td className="col">{item.header.reason}</td>
                              </tr>
                            }
                            form={RejectRequestForm}
                            reason={item.header.reason}
                            updateDeleteInfo={{
                              id: item.header.formId,
                              shippingHandling: item.header.shippingHandling,
                              setLoading,
                              data: item.data,
                              poData: {
                                ...item.poData,
                                requisitionId: item.header.requisitionId
                                // vendors: content[0].poData.vendors,
                                // accounts: content[0].poData.accounts
                              }
                            }}
                            // name="Ben"
                            // reqId={item.header.requisitionId}
                            // itemName={item.data[0].item_name}
                            // quantity={item.data[0].quantity}
                            // unit_price={item.data[0].unit_price}
                            // total_price={item.data[0].total_price}
                            // description={item.data[0].description}
                            modalTitle="Review Rejected Order"
                          />
                        </div>
                      ))}
                  </tbody>
                </table>
                {/* <i>Rejected PO go here.</i> */}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
