import React, { useState, useEffect } from 'react'

import { Button, Form, Alert } from 'react-bootstrap'
import Select from 'react-select'

import MyTable from '../../Table'
import MyRow from '../../Table/TRows/PurchaseRow'
import MyHeader from '../../Table/THeaders/PurchaseForm.header'
import API from '../../../services/poflow.service'
import Loading from '../../Loading'

export default function POEditForm(props) {
  const [validated, setValidated] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [vendors, setVendors] = useState([])
  const [accounts, setAccounts] = useState({})
  const [selectedVendor, setSelectedVendor] = useState({})
  const [selectedAccount, setSelectedAccount] = useState({})
  const [selectedAddress, setSelectedAddress] = useState([])
  const [allData, setData] = useState(props ? [...props.post.data] : [])

  const reducer = (partialSum, a) => {
    return partialSum + a.total_price
  }
  const [grandTotal, setGrandTotal] = useState(
    props ? props.post.data.reduce(reducer, 0) : 0
  )
  const [shippingHandling, setShippingHandling] = useState(0.0)
  // console.log(props)

  const resetState = () => {
    setValidated(false)
    setSelectedVendor({
      value: props.post.poData.vendorId,
      label: props.post.poData.vendorName
    })
    setSelectedAccount({
      value: props.post.poData.accountId,
      label: props.post.poData.description,
      accountCode: props.post.poData.accountCode
    })
    setData(props ? [...props.post.data] : [])
    setGrandTotal(props ? props.post.data.reduce(reducer, 0) : 0)
    setShippingHandling(props.post.shippingHandling)
  }

  useEffect(() => {
    const fetchData = () => {
      setLoading(false)
      console.log(props)
      setVendors([{ value: 0, label: 'VENDOR OVERRIDE' }, ...props.post.poData.vendors])
      setAccounts([
        { value: 0, label: 'ACCOUNT OVERRIDE', accountCode: '0.0.0.0.0' },
        ...props.post.poData.accounts
      ])
      setSelectedVendor({
        value: props.post.poData.vendorId,
        label: props.post.poData.vendorName
      })
      setSelectedAccount({
        value: props.post.poData.accountId,
        label: props.post.poData.description,
        accountCode: props.post.poData.accountCode
      })
      setShippingHandling(props.post.shippingHandling)
    }
    if (isLoading) {
      fetchData()
    }
  }, [isLoading, props])

  useEffect(() => {
    const fetchData = async () => {
      // setLoading(false)

      const response = await API.getAddresses({
        vendorId: selectedVendor.value && selectedVendor.value
      })
      // console.log(response.data)
      setSelectedAddress(response.data)
    }
    if (selectedVendor.value !== 0) {
      fetchData()
    } else {
      setSelectedAddress([])
    }
  }, [selectedVendor])

  const updateFields = (i, k, v) => {
    const currentData = [...allData]
    if (typeof v === 'string') {
      currentData[i][k] = v || currentData[i][k]
    } else {
      currentData[i][k] = v.label || currentData[i][k]
      currentData[i]['vendorId'] = v.value || currentData[i][k]
    }
    if (currentData[i][k] !== allData[i][k]) {
      setData(currentData)
    }
  }

  function round(num) {
    const m = Number((Math.abs(num) * 100).toPrecision(15))
    return (Math.round(m) / 100) * Math.sign(num)
  }

  const updateTotal = (i, k, v) => {
    const currentData = [...allData]
    const k2 = k === 'quantity' ? 'unit_price' : 'quantity'
    currentData[i][k] = parseFloat(v || currentData[i][k])
    currentData[i].total_price = round(currentData[i][k] * currentData[i][k2])
    setData(currentData)
    updateGrandTotal(currentData)
  }
  const updateGrandTotal = (data) => {
    let total = 0
    data.forEach((obj) => {
      if (typeof obj.total_price == 'number') {
        total += obj.total_price
      }
    })
    setGrandTotal(total)
  }

  const deleteRow = (i) => {
    const currentData = [...allData]
    if (currentData.length > 1) {
      currentData.splice(i, 1)
      setData(currentData)
      updateGrandTotal(currentData)
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setValidated(false)

    const formData = new FormData(e.target)
    const formDataObj = Object.fromEntries(formData.entries())

    // console.log(formDataObj.address)

    let data = {
      formId: props.post.id,
      items: allData.map((obj) => {
        return {
          rowId: obj.id,
          item_name: obj.item_name,
          description: obj.description,
          unit_price: obj.unit_price,
          quantity: obj.quantity,
          total_price: obj.total_price
        }
      }),
      requestFormData: {
        poNumber: parseInt(formDataObj.poNumber),
        shippingHandling: shippingHandling,
        approvalLevel: props.status.level + 1,
        addressId: formDataObj.address
      }
    }
    if (selectedVendor.value !== props.post.poData.vendorId) {
      data.requestFormData.vendorId = selectedVendor.value
    }

    if (selectedAccount.value !== props.post.poData.accountId) {
      data.requestFormData.accountId = selectedAccount.value
    }

    if (formDataObj.overrideAccountName) {
      data.requestFormData.override_account = `${formDataObj.overrideAccountName}|${formDataObj.overrideAccountCode}`
      data.requestFormData.accountId = 0
    }

    if (formDataObj.overrideVendorName) {
      data.requestFormData.override_vendor = `${formDataObj.overrideVendorName}|${formDataObj.overrideVendorCode}|${formDataObj.overrideVendorAddress}|${formDataObj.overrideVendorcityStateZip}`
      data.requestFormData.vendorId = 0
      data.requestFormData.addressId = null
    }

    const sendData = async (data) => {
      props.onHide()
      // console.log(data)
      await API.updateForm(data)
      props.post.setLoading(true)
    }
    const form = e.target
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
      setValidated(true)
    } else {
      resetState()
      sendData(data)
    }
  }

  const closeModal = () => {
    props.onHide()
    resetState()
  }

  return (
    <>
      {isLoading && <Loading />}
      <Form noValidate validated={validated} onSubmit={onFormSubmit}>
        <h3>Name: {props.post.poData.name}</h3>
        <div className="row">
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                <h4>
                  <b>
                    Vendor (code: {selectedVendor.value}):{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </b>
                </h4>
              </Form.Label>
              <Select
                name="vendor"
                options={vendors}
                defaultValue={{
                  label: props.post.poData.vendorName,
                  value: props.post.poData.vendorId
                }}
                onChange={setSelectedVendor}
              />
            </Form.Group>
            {selectedVendor.value === 0 && (
              <div className="row">
                <Form.Group className="col-6">
                  <Form.Label>
                    <h6>
                      Override vendor name: <span style={{ color: 'red' }}>*</span>
                    </h6>
                  </Form.Label>
                  <Form.Control
                    required
                    name="overrideVendorName"
                    defaultValue={props.post.poData.override.overrideVendorName || ''}
                  />
                </Form.Group>
                <Form.Group className="col-6">
                  <Form.Label>
                    <h6>
                      Override vendor code: <span style={{ color: 'red' }}>*</span>
                    </h6>
                  </Form.Label>
                  <Form.Control
                    required
                    name="overrideVendorCode"
                    defaultValue={props.post.poData.override.overrideVendorCode || ''}
                  />
                </Form.Group>
                <Form.Group className="col-6">
                  <Form.Label>
                    <h6>
                      Override vendor Address: <span style={{ color: 'red' }}>*</span>
                    </h6>
                  </Form.Label>
                  <Form.Control
                    required
                    name="overrideVendorAddress"
                    defaultValue={props.post.poData.override.overrideVendorAddress || ''}
                  />
                </Form.Group>
                <Form.Group className="col-6">
                  <Form.Label>
                    <h6>
                      Override vendor city, state, and zip:{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </h6>
                  </Form.Label>
                  <Form.Control
                    required
                    name="overrideVendorcityStateZip"
                    defaultValue={
                      props.post.poData.override.overrideVendorcityStateZip || ''
                    }
                  />
                </Form.Group>
              </div>
            )}
          </div>
          <div className="col-6">
            <Form.Group>
              <Form.Label>
                <h4>
                  <b>
                    Account (code: {selectedAccount.accountCode}):{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </b>
                </h4>
              </Form.Label>
              <Select
                name="account"
                options={accounts}
                defaultValue={{
                  label: props.post.poData.description,
                  value: props.post.poData.accountId,
                  accountCode: props.post.poData.accountCode
                }}
                onChange={setSelectedAccount}
              />
            </Form.Group>
            {selectedAccount.value === 0 && (
              <>
                <Form.Group>
                  <Form.Label>
                    <h6>
                      Override account name: <span style={{ color: 'red' }}>*</span>
                    </h6>
                  </Form.Label>
                  <Form.Control
                    required
                    name="overrideAccountName"
                    defaultValue={props.post.poData.override.overrideAccountName || ''}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <h6>
                      Override account code: <span style={{ color: 'red' }}>*</span>
                    </h6>
                  </Form.Label>
                  <Form.Control
                    required
                    name="overrideAccountCode"
                    defaultValue={props.post.poData.override.overrideAccountCode || ''}
                  />
                </Form.Group>
              </>
            )}
          </div>

          <Form.Group className="col-12">
            <MyTable
              tData={allData}
              sample={{ ...allData[0] }}
              header={MyHeader}
              row={MyRow}
              purchaseFuncs={{
                updateTotal,
                updateFields,
                deleteRow,
                Select: (func, d) => (
                  <Select
                    className="override-input"
                    options={vendors}
                    onChange={func}
                    defaultInputValue={d}
                  />
                )
              }}
            />
          </Form.Group>

          <Form.Group className="col-6 center">
            <Form.Label>
              <strong style={{ color: 'black' }}>
                Shipping and Handling Cost: <span style={{ color: 'red' }}>*</span>
              </strong>
            </Form.Label>
            <Form.Control
              required
              type="number"
              step={0.01}
              onChange={(e) => setShippingHandling(parseFloat(e.target.value))}
              name="shippingHandling"
              placeholder="00.00"
              defaultValue={props.post.shippingHandling}
            />
          </Form.Group>

          <Form.Group className="col-4 center">
            <Form.Label>
              <strong style={{ color: 'black' }}>
                Purchase Order Number: <span style={{ color: 'red' }}>*</span>
              </strong>
            </Form.Label>
            <Form.Control required name="poNumber" maxLength={5} />
          </Form.Group>
        </div>

        <div className="row">
          {selectedAddress.length > 0 && (
            <Form.Group className="col-4 center">
              <Form.Label as="legend">
                <strong style={{ color: 'black' }}>
                  Select an address for purchase order:
                  <span style={{ color: 'red' }}>*</span>
                </strong>{' '}
              </Form.Label>

              {selectedAddress.map((obj, i) => (
                <Form.Check
                  key={i}
                  type="radio"
                  label={
                    <>
                      {obj.secondName && (
                        <>
                          {obj.secondName}
                          <br />
                        </>
                      )}
                      {obj.address}
                      <br />
                      {obj.cityStateZip}
                      <br />
                    </>
                  }
                  name="address"
                  value={obj.nanoId}
                  id={obj.nanoId}
                />
              ))}
            </Form.Group>
          )}

          <div className="col-4 center">
            <h5>
              <strong style={{ color: 'black' }}>
                Grand Total:{' '}
                <span>
                  ${(parseFloat(grandTotal) + parseFloat(shippingHandling)).toFixed(2)}
                </span>
              </strong>
            </h5>
          </div>
        </div>
        {props.post.quote && (
          <h5 className="col-12">
            A quote has been included. To view, click{' '}
            <a href={props.post.quote} target="_blank">
              here
            </a>
            .
          </h5>
        )}
        {props.post.reason && (
          <>
            <h5 className="col-12">NOTE ABOUT PURCHASE ORDER:</h5>
            <p>{props.post.reason}</p>
          </>
        )}
        {validated && (
          <Alert variant="danger">
            Missing required fields or nothing has been entered.
          </Alert>
        )}
        <Button variant="success m-2" type="submit">
          Submit
        </Button>
        <Button variant="warning m-2" onClick={closeModal} type="button">
          Exit
        </Button>
      </Form>
    </>
  )
}
